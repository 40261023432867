// react
import React, { useState, useEffect, useRef, useCallback } from "react";
// Layout
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeaderLayoutComp from "../../layouts-components/common-header-component/header";
import ArchiveFilterComp from "./layout-component/archive-filter";
import ArchiveTable from "../../layouts-components/archive-table-component";
import GenerateMapLayout from "../../layouts-components/generate-map-component/generate-map-component";
import { USER_ID } from "../../common/constants";
// lodash debouncing import
import { debounce } from "lodash";
import { _getStorageValue } from "../../common/localStorage";
import { useSelector, useDispatch } from "react-redux";
import { FILTER_TABLE_COMPONENT } from "../../action/actionConstants";
import {
  archiveTableData,
  getFullPdfData,
  getPartialPdfData,
  getCompactPdfData,
  getOwnershipPdfData,
  getPropertyPdfData,
  tableDropDownStatus,
  Query,
  regenerateReport,
  starred,
  archive,
  reportStatusCount,
} from "../../layouts/archive-report-layout/layout-component/archivefun";
import {
  landList,
  landListMapView,
} from "../../layouts-components/filter-component/filterfun";
// style
import "./archivereport.css";
import moment from "moment";
const ArchiveReportLayout = (props) => {
  let dispatch = useDispatch();
  let {
    starredButtonActiveColor,
    checkPendingStatus,
    onHoldStatus,
    approvedStatus,
    declinedStatus,
    activeList,
    checkArchiveStatus,
    checkStarredStatus,
  } = useSelector((s) => s.filterTableComponentReducer);

  const [tabNav, setTabNav] = useState("");
  const [qParam, setQParam] = useState("");
  const [mapView, setMapView] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [zoomIn, setZoomIn] = useState(false);
  const [mapPolygonData, setMapPolygonData] = useState([]);
  const [coordinates, setCoordinates] = useState([20.5937, 78.9629]);
  const [tableBodyHeight, setTableBodyHeight] = useState("calc(100vh - 254px)");
  const [tableData, setTableData] = useState([]);
  // to determine the satscore report status
  const [satScoreStatus, setSatScoreStatus] = useState(false);
  const [tableDataLoader, setTableDataLoader] = useState(true);
  // to set the ref id
  const [tableRefId, setTableRefId] = useState("");
  // to set modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // to open dropdown menu
  const [anchorEl, setAnchorEl] = useState(false);
  // for checkboxes
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const [checkBoxError, setCheckBoxError] = useState(false);
  // store comments
  const [storeComments, setStoreComments] = useState("");
  // success message
  const [successMessage, setSuccessMessage] = useState(false);
  const [disableTextField, setDisableTextField] = useState(false);
  // store ref no
  const [storeRefNo, setStoreRefNo] = useState("");
  // add active list item color
  const [addActive, setAddActive] = useState(false);
  // to open/close status dropdown.
  const [statusDropDown, setStatusDropDown] = useState(false);
  const [dropdownstate, setDropdownState] = useState(0);
  // to store dynamic tool tip tittle.
  const [toolTipTittle, setToolTipTittle] = useState(false);
  // to toggle the dropdown icon
  const [toggleIcon, setToggleIcon] = useState(false);
  // to store star icon color.
  const [storeStarIconColor, setStoreStarIconColor] = useState(false);
  // store original array
  const [storeOriginalData, setStoreOriginalData] = useState([]);
  // check all starred button active.
  const [buttonActive, setButtonActive] = useState(false);
  // show/hide table buttons.
  const [tableButtons, setTableButtons] = useState(false);
  // store table indexes.
  const [tableIndex, setTableIndex] = useState(false);
  // to store all rows selected.
  const [storeAllRowsSelected, setStoreAllRowsSelected] = useState([]);
  // store table archive status.
  const [storeArchiveStatus, setStoreArchiveStatus] = useState("");
  // store toaster.
  const [showToaster, setShowToaster] = useState(false);
  // store dropdown status name.
  const [storeStatusName, setStoreStatusName] = useState("");
  // store status dropdown tooltip.
  const [statusDropDownToolTip, setStatusDropDownToolTip] = useState(true);
  // store more options dropdown tooltip.
  const [optionsDropDownToolTip, setOptionsDropDownToolTip] = useState(true);
  // to open/close the map view modal.
  const [mapViewModal, setMapViewModal] = useState(false);
  // store the survey details.
  const [storeSurveyDetails, setStoreSurveyDetails] = useState([]);
  // show/hide map view dropdown.
  const [mapViewDropdown, setMapViewDropdown] = useState(false);
  const [map, setMap] = useState(null);
  // to store reqTime.
  const [requestTime, setRequestTime] = useState("");
  // to enable/disable raise query submit button.
  const [activeButton, setActiveButton] = useState(false);

  const [storeRequestId, setStoreRequestId] = useState("");
  // to store the last index for the map view.
  const [isLastIndex, setIsLastIndex] = useState(false);
  // to show a loader while the map renders.
  const [mapViewLoader, showMapViewLoader] = useState(true);
  // show/hide Reportregenerationmodal modal.
  const [showReportRegenerationmodal, setShowReportRegenerationModal] =
    useState(false);
  // store report regenaration checkbox values.
  const [storeCheckBoxLabels, setStoreCheckBoxLabels] = useState([]);
  // enable/disable report regeneration modal button.
  const [disabledButton, setDisabledButton] = useState(true);

  // state to determine which report regenration flow to render.
  const [isReportRenerationSingleFlow, setIsReportRenegerationSingleFlow] =
    useState(false);
  // store individual table row data.
  const [storeIndividualTableRowData, setStoreIndividualTableRowData] =
    useState("");
  // state to determine the single report regeneration flow.
  const [showSingleReportFlow, setShowSingleReportFlow] = useState(false);
  const [storeIndex, setStoreIndex] = useState(0);
  const [storeSurveyNoDetails, setStoreSurveyNoDetails] = useState([]);
  const [totalRows, setTotalRows] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);

  const statusMenu = useRef(null);
  const dropDownOptions = useRef(null);
  const reportDropdown = useRef(null);
  const storeDataRef = useRef([]);

  const setCoordinateValue = (value) => {
    setCoordinates(value);
  };
  const setZoomFalse = () => {
    setZoomIn(false);
  };

  useEffect(() => {
    let path = window.location.pathname;
    setTabNav(path);
  }, [tabNav]);

  // function to handle the opening/closing of dropdown menu
  const handleDropdownMenu = (value, status, event) => {
    setOptionsDropDownToolTip(!optionsDropDownToolTip);
    if (typeof status === "number") {
      setAnchorEl(false);
      setToggleIcon(false);
    } else {
      setAnchorEl(value);
      setToggleIcon(value);
    }
    setStoreRefNo(event);
  };

  // on outside click functionality.
  const handleDropDownMenuClickOutside = (event) => {
    const parentIsSvg =
      event.target.classList.contains("show-dropDown") ||
      event.target.parentNode.classList.contains("show-dropDown");
    if (
      dropDownOptions.current &&
      !dropDownOptions.current.contains(event.target) &&
      !parentIsSvg
    ) {
      setAnchorEl(false);
      setToggleIcon(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      handleDropDownMenuClickOutside,
      true,
    );

    return () => {
      document.removeEventListener(
        "mousedown",
        handleDropDownMenuClickOutside,
        true,
      );
    };
  }, []);

  // function to handle the raise query modal popup.
  const handleQuery = (refId, index) => {
    setTableRefId(refId.refNo);
    setModalIsOpen(true);
    setDisableTextField(true);
    setAddActive(index);
  };

  // function to handle the map view from explorers page
  const handleMapView = (
    refId,
    index,
    requestId,
    storeTableRowData,
    surveyDetails,
  ) => {
    const getDefaultPage = JSON.parse(localStorage.getItem("default page"));
    const defaultValue = getDefaultPage.filter((item) => item.default_page)[0]
      ?.page_name;

    // extract the local storage value for the radio button selection.
    const getStoreRadioButtonSelection = localStorage.getItem(
      "storeRadioButtonSelection",
    );
    // extract the radio button selection value from the local storage.
    const storeRadioButtonSelection = localStorage.getItem(
      "storeRadioButtonSelection",
    );

    storeDataRef.current = [];
    setStoreSurveyNoDetails(surveyDetails.surveyNodetails);
    setIsLastIndex(false);
    setAddActive(index);
    showMapViewLoader(true);
    setStoreIndex(0);
    setMapViewModal(index);
    setStoreRefNo(refId.refNo);
    setRequestTime(refId.reqTime);
    _getStorageValue(USER_ID).then(() => {
      landListMapView(
        surveyDetails.surveyNodetails[storeIndex],
        getStoreRadioButtonSelection === null
          ? defaultValue || storeRadioButtonSelection
          : getStoreRadioButtonSelection,
        successLandCallBack,
        failureLandCallBack,
        storeIndex,
        setStoreIndex,
      );
    });
  };

  useEffect(() => {
    const getDefaultPage = JSON.parse(localStorage.getItem("default page"));
    const defaultValue = getDefaultPage.filter((item) => item.default_page)[0]
      ?.page_name;

    // extract the local storage value for the radio button selection.
    const getStoreRadioButtonSelection = localStorage.getItem(
      "storeRadioButtonSelection",
    );
    // extract the radio button selection value from the local storage.
    const storeRadioButtonSelection = localStorage.getItem(
      "storeRadioButtonSelection",
    );

    if (storeSurveyNoDetails && storeSurveyNoDetails.length > 0) {
      if (storeIndex === storeSurveyNoDetails.length) {
        setIsLastIndex(true);
      }
      if (storeIndex !== 0 && storeIndex !== storeSurveyNoDetails.length) {
        _getStorageValue(USER_ID)
          .then(() => {
            landListMapView(
              storeSurveyNoDetails[storeIndex] !== undefined &&
                storeSurveyNoDetails[storeIndex],
              getStoreRadioButtonSelection === null
                ? defaultValue || storeRadioButtonSelection
                : getStoreRadioButtonSelection,
              successLandCallBack,
              failureLandCallBack,
              storeIndex,
              setStoreIndex,
            );
          })
          .catch((error) => {
            console.error("Error retrieving storage value:", error);
          });
      }
    }
  }, [storeIndex, storeSurveyNoDetails, storeSurveyDetails]);

  // to zoom the map towards the coordinates.
  const zoomInToLocation = () => {
    let storeOriginalArray = [...storeSurveyDetails];
    let polyData = storeOriginalArray;
    let convertObj = polyData.length > 0 && JSON.parse(polyData[0]?.centroid);
    setTimeout(() => {
      if (coordinates === null) {
        setCoordinates([20.5937, 78.9629]);
      } else if (convertObj && convertObj.coordinates.length !== 0) {
        let latlng = convertObj.coordinates.reverse();
        if (map)
          map.flyTo(latlng, 16, {
            duration: 1,
          });
      }
    }, 1000);
  };

  useEffect(() => {
    zoomInToLocation();
  }, [storeSurveyDetails]);

  useEffect(() => {
    if (isLastIndex) {
      showMapViewLoader(false);
      setStoreSurveyDetails(storeDataRef.current);
      setStoreSurveyNoDetails([]);
    }
  }, [isLastIndex]);

  const successLandCallBack = (response) => {
    const mapViewDataRef = storeDataRef.current;
    storeDataRef.current = mapViewDataRef.concat(response);
  };
  const failureLandCallBack = (response) => {
    storeDataRef.current = [];
  };

  // function to handle the regeneration of report from explorers page
  const handleReportRegeneration = (
    tableRowData,
    refId,
    storeRequestId,
    surveyDetails,
    index,
  ) => {
    setStoreIndividualTableRowData(tableRowData);
    setStoreRequestId(storeRequestId);
    setShowReportRegenerationModal(true);
    setIsReportRenegerationSingleFlow(true);
  };

  // function to handle the archieve functionality.
  const handleArchieve = (tableRowData, refId, storeRequestId, index) => {
    let storeTableDataIndex = tableData.findIndex((table) => {
      return table.refNo == tableRowData.refNo;
    });
    let storeStatus;
    storeStatus = tableData[storeTableDataIndex].archiveStatus = true;
    setStoreArchiveStatus(tableData[storeTableDataIndex].archiveStatus);
    setAddActive(index);
    const copyOfOriginalArray = [...tableData];
    setTableData(
      [...copyOfOriginalArray].filter(
        (filteredData) => filteredData.refNo !== tableRowData.refNo,
      ),
    );
    const storeArchiveStatus = [
      {
        archive: JSON.stringify(storeStatus),
        "request-id": tableRowData.requestId,
      },
    ];
    _getStorageValue(USER_ID).then((userID) => {
      archive(
        userID,
        storeArchiveStatus,
        successArchiveCallBack,
        failureArchiveCallBack,
      );
    });
  };

  const successArchiveCallBack = (response) => {
    console.log(response, "response");
    setShowSingleReportFlow(false);
  };

  const failureArchiveCallBack = (error) => {
    console.log(error, "error");
  };

  // function to close modal
  const closePopUp = () => {
    setModalIsOpen(false);
    setCheckBoxError(false);
    setSuccessMessage(false);
    setCheckBoxValues([]);
    setDisableTextField(false);
    setAnchorEl(false);
    setAddActive(false);
    setActiveButton(false);
  };
  // function to handle checkbox state
  const handleCheckboxState = (element) => {
    const storeCheckBoxValue = [];
    var checkboxes = document.querySelectorAll("input[type=radio]:checked");
    for (var i = 0; i < checkboxes.length; i++) {
      storeCheckBoxValue.push(checkboxes[i].value);
    }
    setCheckBoxValues(storeCheckBoxValue);
    setCheckBoxError(false);
    setActiveButton(true);
  };

  const handleComment = (elem) => {
    setStoreComments(elem);
  };

  const raiseQueryPayload = {
    referenceId: tableRefId,
    queryList: checkBoxValues,
    comment: storeComments,
  };
  const handleFormSubmit = debounce(() => {
    setActiveButton(false);
    if (checkBoxValues.length === 0) {
      setCheckBoxError(true);
    } else {
      _getStorageValue(USER_ID).then(() => {
        Query(raiseQueryPayload, successQueryResponse, failQueryResponse);
      });
    }
  }, 100);

  const successQueryResponse = (response) => {
    setSuccessMessage(true);

    setTimeout(() => {
      location.reload();
    }, 1000);
  };

  const failQueryResponse = (response) => {
    console.log(response);
  };

  const getArchiveData = () => {
    _getStorageValue(USER_ID).then(() => {
      archiveTableData(successResponse, failResponse, pageNumber, qParam);
    });
  };

  const successResponse = (response) => {
    mapDataSet(response.requests);
    setTableDataLoader(false);
    setOptionsDropDownToolTip(false);
    setTotalRows(response.totalRows);
    // dispatch({
    //   type: FILTER_TABLE_COMPONENT,
    //   payLoad: {
    //     defaultColor: true,
    //     allFilesButtonActiveColor: true
    //   },
    // });
  };
  const failResponse = (response) => {
    setTableDataLoader(false);
  };

  // function to handle text capitalization
  function titleCase(str) {
    var splitStr = str
      .toLowerCase()
      .split(",")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(", ");
    return splitStr;
  }

  function tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }

  const mapDataSet = (res) => {
    let dataObj = [];
    let filteredDataObj = [];
    res.forEach((data, i) => {
      let archiveObj = {
        refNo: data.referenceId,
        reqTime: `${moment(data.requestCreationDate).format("DD/MM/YYYY")}`,
        reqDate: tConvert(data.updateTime),
        state: data.region === null ? "" : titleCase(data.region),
        farmReport: data.satsourceReportStatus,
        farmMonitoringReport: data.ownershipReportStatus,
        propertyReport: data.propertyReportStatus,
        requestId: data.requestId,
        color: "#B4B4B4",
        starredStatus: data.starred,
        dropDownStatus: data.reportStatus,
        archiveStatus: data.archived,
        surveyDetails: data.surveyData,
        regionState: data.state,
        reportReadStatus: data.satsourceReportReadStatus,
      };
      dataObj.push(archiveObj);
    });

    // filtered out the data whose archiveStatus are false.
    dataObj.filter((data) => {
      if (data.archiveStatus === false) {
        return filteredDataObj.push(data);
      }
      if (data.archiveStatus && checkArchiveStatus) {
        return filteredDataObj.push(data);
      }
    });

    setTableData(filteredDataObj);
    setStoreOriginalData(dataObj);
  };

  // pdf data
  const successPdfResponse = (response) => {
    // const setPdf = response[0].reportLink;
    window.open(response[0].reportLink);
    getArchiveData();
  };
  const failPdfResponse = (response) => {
    console.log("fail", response);
  };

  // pdf data
  const successOwnershipPdfResponse = (response) => {
    // const setPdf = response[0].reportLink;
    window.open(response[0].reportLink);
  };
  const failOwnershipPdfResponse = (response) => {
    console.log("fail", response);
  };

  const successPropertyPdfResponse = (response) => {
    // const setPdf = response[0].reportLink;
    window.open(response[0].reportLink);
  };
  const failPropertyPdfResponse = (response) => {
    console.log("fail", response);
  };

  // const copyOfOriginalArray = [...tableData];
  // const storeDesicionPendingData = copyOfOriginalArray.filter((decisionPendingData) => decisionPendingData.dropDownStatus === "pending");
  // const storeOnHoldData = copyOfOriginalArray.filter((onHoldData) => onHoldData.dropDownStatus === "onhold");
  // const storeApprovedData = copyOfOriginalArray.filter((approvedData) => approvedData.dropDownStatus === "approved");
  // const storeDeclinedData = copyOfOriginalArray.filter((declinedData) => declinedData.dropDownStatus === "declined");
  // const storeStarredData = copyOfOriginalArray.filter((filteredItems) => filteredItems.starredStatus === true);
  // const storeArchiveData = copyOfOriginalArray.filter((archiveData) => archiveData.archiveStatus === true);

  useEffect(() => {
    getArchiveData();
  }, [pageNumber, qParam]);

  useEffect(() => {
    setPageNumber(0);
    if (activeList === 0) {
      setQParam("");
    }
    if (activeList === 1) {
      setQParam("pending");
    }
    if (activeList === 2) {
      setQParam("onhold");
    }
    if (activeList === 3) {
      setQParam("approved");
    }
    if (activeList === 4) {
      setQParam("declined");
    }
    if (checkArchiveStatus) {
      setQParam("archive");
    }
    if (checkStarredStatus) {
      setQParam("starred");
    }
  }, [activeList, checkArchiveStatus, checkStarredStatus]);

  // recall the generate report table api after every 5 seconds and stop the api call when all or some of the report status are completed.
  useEffect(() => {
    // implementing the array some method to find if some of the elements inside the array statifies a certain condition.
    const hasValidReport = tableData.some((getEveryStatus) => {
      return (
        getEveryStatus.farmReport === -1 ||
        getEveryStatus.farmReport === 0 ||
        getEveryStatus.farmMonitoringReport === -1 ||
        getEveryStatus.farmMonitoringReport === 0 ||
        getEveryStatus.propertyReport === -1 ||
        getEveryStatus.propertyReport === 0
      );
    });

    const myTimer = () => {
      // comparing both the booleans.
      if (
        checkPendingStatus ||
        onHoldStatus ||
        declinedStatus ||
        approvedStatus ||
        checkArchiveStatus
      ) {
        return null;
      } else if (hasValidReport) {
        getArchiveData();
        setTableButtons(false);
      } else {
        return null;
      }
    };
    let myInterval = setInterval(myTimer, 5000);
    return () => {
      clearInterval(myInterval);
    };
  });

  //full pdf download
  const exportPdfTrigger = (requestId, reportStatus) => {
    _getStorageValue(USER_ID).then((userID) => {
      getFullPdfData(
        userID,
        requestId,
        reportStatus,
        successPdfResponse,
        failPdfResponse,
      );
    });
  };
  //partial pdf download
  const exportPartialPdfTrigger = (requestId, reportStatus) => {
    _getStorageValue(USER_ID).then((userID) => {
      getPartialPdfData(
        userID,
        requestId,
        reportStatus,
        successPdfResponse,
        failPdfResponse,
      );
    });
  };

  //compact pdf download
  const exportCompactPdfTrigger = (requestId, reportStatus) => {
    _getStorageValue(USER_ID).then((userID) => {
      getCompactPdfData(
        userID,
        requestId,
        reportStatus,
        successPdfResponse,
        failPdfResponse,
      );
    });
  };

  // pdf download
  const exportOwnershipPdfTrigger = (requestId, reportStatus) => {
    _getStorageValue(USER_ID).then((userID) => {
      getOwnershipPdfData(
        userID,
        requestId,
        reportStatus,
        successOwnershipPdfResponse,
        failOwnershipPdfResponse,
      );
    });
  };

  const exportPropertyPdfTrigger = (requestId, reportStatus) => {
    _getStorageValue(USER_ID).then((userID) => {
      getPropertyPdfData(
        userID,
        requestId,
        reportStatus,
        successPropertyPdfResponse,
        failPropertyPdfResponse,
      );
    });
  };
  // on click of status arrow dropdown.
  const handleArrowClick = (id, status) => {
    setStatusDropDownToolTip(!statusDropDownToolTip);
    if (typeof status === "number") {
      setStatusDropDown(false);
    } else {
      setStatusDropDown(id);
    }
  };

  // on outside click functionality.
  const handleClickOutside = (event) => {
    const parentIsSvg =
      event.target.classList.contains("dont-close-on-outside-click") ||
      event.target.parentNode.classList.contains("dont-close-on-outside-click");
    if (
      statusMenu.current &&
      !statusMenu.current.contains(event.target) &&
      !parentIsSvg
    ) {
      setStatusDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  const handleGenerateReportDropdown = (e) => {
    var flag;
    dropdownstate === 1 ? (flag = 0) : (flag = 1);
    if (flag === 0) {
      var dropdownElements = document.getElementsByClassName("down");
      for (var i = 0; i < dropdownElements.length; i += 1) {
        dropdownElements[i].style.display = "none";
      }
      document.getElementById(e.currentTarget.id).nextSibling.style.display =
        "none";
    } else {
      var dropdownElements = document.getElementsByClassName("down");
      for (var i = 0; i < dropdownElements.length; i += 1) {
        dropdownElements[i].style.display = "none";
      }
      document.getElementById(e.currentTarget.id).nextSibling.style.display =
        "block";
    }
    setDropdownState(flag);
  };

  // on outside click functionality.
  const handleDropdownClickOutside = (event) => {
    const isListItem =
      event.target.classList.contains("dropdown-outside-click") ||
      event.target.parentNode.classList.contains("dropdown-outside-click");
    if (
      reportDropdown.current &&
      !reportDropdown.current.contains(event.target) &&
      !isListItem
    ) {
      setDropdownState(0);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDropdownClickOutside, true);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleDropdownClickOutside,
        true,
      );
    };
  }, []);

  const handleBriefReportDownload = (e, tableReportData) => {
    if (e.target.innerHTML === "Brief Report(2MB)") {
      exportPartialPdfTrigger(
        tableReportData.requestId,
        tableReportData.farmReport,
      );
      var dropdownElements = document.getElementsByClassName("down");
      for (var i = 0; i < dropdownElements.length; i += 1) {
        dropdownElements[i].style.display = "none";
      }
      setDropdownState(0);
    }
  };

  const handleDetailedReportDownload = (e, reportData) => {
    if (e.target.innerHTML === "Detailed Report") {
      exportPdfTrigger(reportData.requestId, reportData.farmReport);
      var dropdownElements = document.getElementsByClassName("down");
      for (var i = 0; i < dropdownElements.length; i += 1) {
        dropdownElements[i].style.display = "none";
      }
      setDropdownState(0);
    }
  };

  const handleCompactReportDownload = (e, reportData) => {
    if (e.target.innerHTML === "Compact Report") {
      exportCompactPdfTrigger(reportData.requestId, reportData.farmReport);
      var dropdownElements = document.getElementsByClassName("down");
      for (var i = 0; i < dropdownElements.length; i += 1) {
        dropdownElements[i].style.display = "none";
      }
      setDropdownState(0);
    }
  };

  const handleStatusColor = (
    statusColor,
    statusType,
    tableRefId,
    statusName,
  ) => {
    setStoreStatusName(statusName);
    setShowToaster(!showToaster);
    setTimeout(() => {
      setShowToaster((showToaster) => !showToaster);
    }, 1000);
    const cloneOriginalArray = [...tableData];
    let storeTableDataIndex = tableData.findIndex((table) => {
      return table.requestId == tableRefId.requestId;
    });
    tableData[storeTableDataIndex].color = statusColor;
    tableData[storeTableDataIndex].dropDownStatus = statusType;
    setStatusDropDown(false);
    _getStorageValue(USER_ID).then((userID) => {
      tableDropDownStatus(
        userID,
        tableRefId.requestId,
        statusType,
        successReportStatusCallBack,
        failureReportStatusCallBack,
      );
    });
    // this if condition will check if the status are true based on the boolean value, I am filtering out the data checking if it's not equal to the respective value then delete the item.
    if (
      checkPendingStatus ||
      onHoldStatus ||
      approvedStatus ||
      declinedStatus
    ) {
      setTableData(
        [...cloneOriginalArray].filter(
          (filteredData) => filteredData.requestId != tableRefId.requestId,
        ),
      );
    } else {
      setTableData([...tableData]);
    }
  };

  const successReportStatusCallBack = (response) => {
    console.log(response, "report status");
  };
  const failureReportStatusCallBack = (response) => {
    console.log(response, "failed report status");
  };

  // handle starred functionality.
  const handleStarIcon = (id, tableRowData) => {
    const immutableTableData = [...tableData];
    setToolTipTittle({
      ...toolTipTittle,
      [id]: !toolTipTittle[id],
    });
    let storeTableDataIndex = immutableTableData.findIndex((table) => {
      return table.requestId == tableRowData.requestId;
    });
    const newStarredStatus = !tableRowData.starredStatus;
    immutableTableData[storeTableDataIndex].starredStatus = newStarredStatus;
    setSatScoreStatus(newStarredStatus);
    if (starredButtonActiveColor) {
      setTableData(
        [...immutableTableData].filter(
          (filteredData) => filteredData.requestId != tableRowData.requestId,
        ),
      );
      setStoreStarIconColor(false);
      setToolTipTittle(false);
      const storeStarredStatus = [
        {
          starred: "false",
          "request-id": tableRowData.requestId,
        },
      ];
      _getStorageValue(USER_ID).then((userID) => {
        starred(
          userID,
          storeStarredStatus,
          successStarredCallBack,
          failureStarredCallBack,
        );
      });
    } else {
      setStoreStarIconColor({
        ...storeStarIconColor,
        [id]: !storeStarIconColor[id],
      });
      const storeStarredStatus = [
        {
          starred: JSON.stringify(newStarredStatus),
          "request-id": tableRowData.requestId,
        },
      ];
      _getStorageValue(USER_ID).then((userID) => {
        starred(
          userID,
          storeStarredStatus,
          successStarredCallBack,
          failureStarredCallBack,
        );
      });
      setTableData([...immutableTableData]);
    }
  };
  const successStarredCallBack = (response) => {
    console.log(response, "starred status");
    setShowSingleReportFlow(false);
  };

  const failureStarredCallBack = (response) => {
    console.log(response, "failed starred status");
  };

  // add star to all...
  const handleAllStarred = (status) => {
    let copyOfOriginalArray = [...tableData];
    let starredRows = {};
    const storeStarredStatus = [];
    if (
      storeOriginalData[0].starredStatus === status &&
      buttonActive === true
    ) {
      storeAllRowsSelected.forEach((arr) => {
        tableData[arr.index].starredStatus = "";
      });
    } else {
      storeAllRowsSelected.forEach((arr) => {
        copyOfOriginalArray.forEach((data, index) => {
          if (index === arr.index) {
            data.starredStatus = true;
            storeStarredStatus.push({
              starred: JSON.stringify(data.starredStatus),
              "request-id": data.requestId,
            });
          }
        });
      });
      setTableData(copyOfOriginalArray);
      setStoreStarIconColor({
        ...storeStarIconColor,
        ...starredRows,
      });
      setButtonActive(false);
      setTableButtons(false);
      setStoreAllRowsSelected([]);
      _getStorageValue(USER_ID).then((userID) => {
        starred(
          userID,
          storeStarredStatus,
          successAllStarredCallBack,
          failureAllStarredCallBack,
        );
      });
    }
  };

  const successAllStarredCallBack = (response) => {
    console.log(response, "starred status");
    setShowSingleReportFlow(false);
  };

  const failureAllStarredCallBack = (response) => {
    console.log(response, "failed starred status");
  };

  // handle report regeneration modal checkboxes.
  const handleReportRegenerationCheckboxes = (checkStatus, checkboxLabels) => {
    // Remove spaces between "satsource" and "ownership"
    checkboxLabels = checkboxLabels.replace(/\s&\s/g, "&");
    if (checkStatus === true) {
      setStoreCheckBoxLabels([...storeCheckBoxLabels, checkboxLabels]);
    } else if (checkStatus === false) {
      setStoreCheckBoxLabels(
        [...storeCheckBoxLabels].filter(
          (filteredLabels) => filteredLabels !== checkboxLabels,
        ),
      );
    }
  };

  // use effect to enable/disable the report regeneration modal button based on the checkbox selected.
  useEffect(() => {
    if (storeCheckBoxLabels.length === 0) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [storeCheckBoxLabels]);

  const handleAllReportRenegerate = () => {
    setShowReportRegenerationModal(true);
  };

  //  handle Selected Report Regeneration .
  const handleSelectedReportRegeneration = () => {
    // creating an empty array and looping over the two arrays. The storeAllRowsSelected is an array of indexes which is coming from the checkboxes and the other one is the main table data array then comparing the ids of the both the arrays and extracting out the request id from the table data array.

    const copyOriginalArray = [...tableData];
    const storeRegionNames = [];
    const storeReportTypes = [];
    const storeTableRequestId = [];

    const reportTypeMappings = {
      SatSource: ["satsource"],
      ownership: ["ownership"],
      Property: ["property"],
    };

    storeCheckBoxLabels.forEach((item) => {
      const reportTypes = reportTypeMappings[item];
      if (reportTypes) {
        storeReportTypes.push(...reportTypes);
      }
    });

    storeAllRowsSelected.forEach((data) => {
      copyOriginalArray.forEach((rowData, i) => {
        if (data.index === i) {
          storeTableRequestId.push({
            request_id: rowData.requestId,
          });
          storeRegionNames.push(rowData.state);
        }
      });
    });

    const reportRegenerationPayload = {
      report_types: storeReportTypes,
      report_request: [
        {
          request_id: storeRequestId,
        },
      ],
    };

    const storeMultipleReportRegenerationPayload = {
      report_types: storeReportTypes,
      report_request: storeTableRequestId,
    };

    setButtonActive(false);
    setTableButtons(false);
    setStoreAllRowsSelected([]);
    setTableData(copyOriginalArray);
    _getStorageValue(USER_ID).then(() => {
      regenerateReport(
        isReportRenerationSingleFlow === true
          ? reportRegenerationPayload
          : storeMultipleReportRegenerationPayload,
        successAllReportRegenerationCallBack,
        failureAllReportRegenerationStatusCallBack,
      );
    });
  };

  // regenerate report for satsource and onwership.
  const successAllReportRegenerationCallBack = (response) => {
    getArchiveData();
    setTableButtons(false);
    setShowReportRegenerationModal(false);
    setStoreCheckBoxLabels([]);
    setShowSingleReportFlow(false);
    localStorage.removeItem("storeRadioButtonSelection");
  };

  const failureAllReportRegenerationStatusCallBack = (response) => {
    console.log(response, "failed report regeneration");
  };

  // close checkbox modal.
  const handleCheckboxModalClose = () => {
    setShowReportRegenerationModal(false);
    setTableButtons(false);
    setStoreAllRowsSelected([]);
    setStoreCheckBoxLabels([]);
    setShowSingleReportFlow(false);
  };

  // handle all archieve functionality.
  const handleAllArchieve = (getTableData) => {
    // looping over the two arrays one is the rows selected array and other is the table data array then checking if ids are same then filter out the data which is not equal to the table row data.
    let copyOfOriginalArray = [...tableData];
    let setArchiveStatus = true;
    let storeAllArchivedData = [];
    storeAllRowsSelected.forEach((data) => {
      getTableData.forEach((rowData, i) => {
        if (i === data.index) {
          copyOfOriginalArray = [...copyOfOriginalArray].filter(
            (filteredData) => filteredData.refNo !== rowData.refNo,
          );
          rowData.archiveStatus = setArchiveStatus;
          storeAllArchivedData.push({
            archive: JSON.stringify(setArchiveStatus),
            "request-id": rowData.requestId,
          });
        }
      });
    });

    setTableData(copyOfOriginalArray);
    setStoreArchiveStatus(setArchiveStatus);
    setTableButtons(false);
    setButtonActive(false);
    setStoreAllRowsSelected([]);

    _getStorageValue(USER_ID).then((userID) => {
      archive(
        userID,
        // starredButtonActiveColor ? storeAllUnArchivedData : storeAllArchivedData,
        storeAllArchivedData,
        successAllArchiveCallBack,
        failureALLArchiveCallBack,
      );
    });
  };

  const successAllArchiveCallBack = (response) => {
    console.log(response, "response");
    setShowSingleReportFlow(false);
  };

  const failureALLArchiveCallBack = (error) => {
    console.log(error, "error");
  };

  // on change function to handle the checkboxes.
  const handleCheckBoxes = (currentRowsSelected, allRowsSelected) => {
    setShowSingleReportFlow(true);
    setIsReportRenegerationSingleFlow(false);
    setStoreAllRowsSelected(allRowsSelected);
    setTableIndex(currentRowsSelected[0]?.index);
    setTableButtons({
      ...tableButtons,
      [currentRowsSelected[0]?.index]:
        !tableButtons[currentRowsSelected[0]?.index],
    });
  };

  // on click event for unarchieve data.
  const handleUnarchieveData = (tableRefNo) => {
    const cloneTableData = [...tableData];
    let storeTableDataIndex = cloneTableData.findIndex((table) => {
      return table.requestId == tableRefNo.requestId;
    });
    if (
      onHoldStatus ||
      approvedStatus ||
      checkPendingStatus ||
      declinedStatus
    ) {
      setTableData([...cloneTableData]);
    } else {
      let unArchiveData;
      unArchiveData = cloneTableData[storeTableDataIndex].archiveStatus = false;
      setTableData(
        [...cloneTableData].filter(
          (filteredData) => filteredData.requestId != tableRefNo.requestId,
        ),
      );
      const storeUnArchiveDataPayload = [
        {
          archive: JSON.stringify(unArchiveData),
          "request-id": tableRefNo.requestId,
        },
      ];
      _getStorageValue(USER_ID).then((userID) => {
        archive(
          userID,
          storeUnArchiveDataPayload,
          successUnArchiveCallBack,
          failureUnArchiveCallBack,
        );
      });
    }
  };

  const successUnArchiveCallBack = (response) => {
    console.log(response, "response");
    setShowSingleReportFlow(false);
  };

  const failureUnArchiveCallBack = (error) => {
    console.log(error, "error");
  };

  // function for unarchiving all selected records.
  const handleAllUnArchieve = (extractTableData) => {
    let copyOfOriginalArray = [...tableData];
    let setArchiveStatus = false;
    let storeAllUnArchivedData = [];
    storeAllRowsSelected.forEach((data) => {
      extractTableData.forEach((rowData, i) => {
        if (i === data.index) {
          copyOfOriginalArray = [...copyOfOriginalArray].filter(
            (filteredData) => filteredData.refNo !== rowData.refNo,
          );
          rowData.archiveStatus = setArchiveStatus;
          storeAllUnArchivedData.push({
            archive: JSON.stringify(setArchiveStatus),
            "request-id": rowData.requestId,
          });
        }
      });
    });
    _getStorageValue(USER_ID).then((userID) => {
      archive(
        userID,
        storeAllUnArchivedData,
        successAllUnArchiveCallBack,
        failureAllUnArchiveCallBack,
      );
    });
    setTableData(copyOfOriginalArray);
    setStoreArchiveStatus(setArchiveStatus);
    setTableButtons(false);
    setStoreAllRowsSelected([]);
  };

  const successAllUnArchiveCallBack = (response) => {
    console.log(response, "response");
    setShowSingleReportFlow(false);
  };

  const failureAllUnArchiveCallBack = (error) => {
    console.log(error, "error");
  };

  // function to unstar all the selected records.
  const handleAllUnstarred = (fetchTableData) => {
    let copyOfOriginalArray = [...tableData];
    const storeUnStarredStatus = [];
    const starIconStatus = false;
    storeAllRowsSelected.forEach((arr) => {
      fetchTableData.forEach((data, index) => {
        if (index === arr.index) {
          copyOfOriginalArray = [...copyOfOriginalArray].filter(
            (filteredData) => filteredData.refNo !== data.refNo,
          );
          data.starredStatus = starIconStatus;
          storeUnStarredStatus.push({
            starred: JSON.stringify(starIconStatus),
            "request-id": data.requestId,
          });
        }
      });
    });

    _getStorageValue(USER_ID).then((userID) => {
      starred(
        userID,
        storeUnStarredStatus,
        successAllUnStarredCallBack,
        failureAllUnStarredCallBack,
      );
    });

    setTableData(copyOfOriginalArray);
    setStoreStarIconColor(false);
    setButtonActive(false);
    setTableButtons(false);
    setStoreAllRowsSelected([]);
  };

  const successAllUnStarredCallBack = (response) => {
    console.log(response, "starred status");
    setShowSingleReportFlow(false);
  };

  const failureAllUnStarredCallBack = (response) => {
    console.log(response, "failed starred status");
  };

  // map view dropdown toggle functionality.
  const handleDropDownToogle = () => {
    setMapViewDropdown(!mapViewDropdown);
  };

  return (
    <div className="archive-report-sections">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <ArchiveFilterComp
              successResponse={successResponse}
              failResponse={failResponse}
              setTableData={setTableData}
              setTableButtons={setTableButtons}
              storeOriginalData={storeOriginalData}
              buttonActive={buttonActive}
              setStoreArchiveStatus={setStoreArchiveStatus}
              setStoreAllRowsSelected={setStoreAllRowsSelected}
              setAddActive={setAddActive}
            />
          </Grid>
          <Grid item xs={10}>
            <div className="archive-report-wrapper">
              <HeaderLayoutComp tabNav={tabNav} />
              {(() => {
                if (mapView) {
                  return (
                    <GenerateMapLayout
                      mapPolygonData={mapPolygonData}
                      zoomIn={zoomIn}
                      zoomFalse={setZoomFalse}
                      isMapView={mapView}
                      isTableView={tableView}
                      coordinates={coordinates}
                    />
                  );
                } else if (tableView) {
                  return (
                    <ArchiveTable
                      zoomIn={zoomIn}
                      selectedRowProps={props.selectedRowProps}
                      setCoordinateValue={setCoordinateValue}
                      tableBodyHeight={tableBodyHeight}
                      tableData={tableData}
                      satScoreStatus={satScoreStatus}
                      tableDataLoader={tableDataLoader}
                      modalIsOpen={modalIsOpen}
                      anchorEl={anchorEl}
                      checkBoxValues={checkBoxValues}
                      checkBoxError={checkBoxError}
                      storeComments={storeComments}
                      successMessage={successMessage}
                      disableTextField={disableTextField}
                      storeRefNo={storeRefNo}
                      addActive={addActive}
                      exportOwnershipPdfTrigger={exportOwnershipPdfTrigger}
                      exportPropertyPdfTrigger={exportPropertyPdfTrigger}
                      exportPdfTrigger={exportPdfTrigger}
                      exportPartialPdfTrigger={exportPartialPdfTrigger}
                      handleDropdownMenu={handleDropdownMenu}
                      tableRefId={tableRefId}
                      open={open}
                      handleArchieve={handleArchieve}
                      handleReportRegeneration={handleReportRegeneration}
                      handleMapView={handleMapView}
                      handleQuery={handleQuery}
                      handleFormSubmit={handleFormSubmit}
                      handleCheckboxState={handleCheckboxState}
                      handleComment={handleComment}
                      closePopUp={closePopUp}
                      handleArrowClick={handleArrowClick}
                      statusDropDown={statusDropDown}
                      handleGenerateReportDropdown={
                        handleGenerateReportDropdown
                      }
                      dropdownstate={dropdownstate}
                      handleBriefReportDownload={handleBriefReportDownload}
                      handleDetailedReportDownload={
                        handleDetailedReportDownload
                      }
                      handleCompactReportDownload={handleCompactReportDownload}
                      handleStatusColor={handleStatusColor}
                      toolTipTittle={toolTipTittle}
                      toggleIcon={toggleIcon}
                      handleStarIcon={handleStarIcon}
                      storeStarIconColor={storeStarIconColor}
                      handleAllStarred={handleAllStarred}
                      handleCheckBoxes={handleCheckBoxes}
                      tableButtons={tableButtons}
                      tableIndex={tableIndex}
                      handleAllReportRenegerate={handleAllReportRenegerate}
                      handleUnarchieveData={handleUnarchieveData}
                      storeArchiveStatus={storeArchiveStatus}
                      showToaster={showToaster}
                      storeStatusName={storeStatusName}
                      handleAllArchieve={handleAllArchieve}
                      statusDropDownToolTip={statusDropDownToolTip}
                      optionsDropDownToolTip={optionsDropDownToolTip}
                      mapViewModal={mapViewModal}
                      setMapViewModal={setMapViewModal}
                      storeSurveyDetails={storeSurveyDetails}
                      handleDropDownToogle={handleDropDownToogle}
                      mapViewDropdown={mapViewDropdown}
                      setMap={setMap}
                      map={map}
                      storeAllRowsSelected={storeAllRowsSelected}
                      requestTime={requestTime}
                      handleAllUnArchieve={handleAllUnArchieve}
                      handleAllUnstarred={handleAllUnstarred}
                      activeButton={activeButton}
                      statusMenu={statusMenu}
                      dropDownOptions={dropDownOptions}
                      reportDropdown={reportDropdown}
                      setMapViewDropdown={setMapViewDropdown}
                      storeRequestId={storeRequestId}
                      mapViewLoader={mapViewLoader}
                      showReportRegenerationmodal={showReportRegenerationmodal}
                      handleReportRegenerationCheckboxes={
                        handleReportRegenerationCheckboxes
                      }
                      disabledButton={disabledButton}
                      handleSelectedReportRegeneration={
                        handleSelectedReportRegeneration
                      }
                      handleCheckboxModalClose={handleCheckboxModalClose}
                      storeIndividualTableRowData={storeIndividualTableRowData}
                      showSingleReportFlow={showSingleReportFlow}
                      setStoreSurveyNoDetails={setStoreSurveyNoDetails}
                      setStoreIndex={setStoreIndex}
                      totalRows={totalRows}
                      setPageNumber={setPageNumber}
                      pageNumber={pageNumber}
                      setTotalRows={setTotalRows}
                    />
                  );
                }
              })()}
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ArchiveReportLayout;
