// url
import {
  LOGIN_URL,
  LOGOUT_URL,
  RSA_URL,
  ENCRYPT_DECRYPT,
} from "../../common/urls";
import { changePassword } from "../../common/urls";
// Plugin
import { toast } from "react-toastify";
// api
import { apiCall } from "../../common/connect";
// constant
import {
  DELETE,
  POST,
  GET,
  REFRESH_TOKEN,
  ACCESS_TOKEN,
} from "../../common/constants";
import {
  setAccessToken,
  setUserId,
  setCompId,
  setCompanyConfigs,
  setRefreshToken,
  removeAllCookies,
  _getStorageValue,
} from "../../common/localStorage";

export function login(data, successLoginCallBack, failureLoginCallBack) {
  const onSuccess = (response) => {
    setAccessToken(response.data.accessToken);
    setUserId(response.data.userId);
    setRefreshToken(response.data.refreshToken);
    setCompId(response.data.companyId);
    setCompanyConfigs(JSON.stringify(response.data.companyConfigs));

    successLoginCallBack(response);
  };

  const onFailure = (response) => {
    failureLoginCallBack(response);
  };

  apiCall(POST, LOGIN_URL, data, onSuccess, onFailure);
}

export function logout(successLogoutCallBack, failureLogoutCallBack) {
  const onSuccess = (response) => {
    toast.success("Login Success", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    removeAllCookies();
    successLogoutCallBack(response);
  };

  const onFailure = (response) => {
    toast.error("Please check login details", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    removeAllCookies();
    failureLogoutCallBack(response);
  };
  _getStorageValue(REFRESH_TOKEN).then((token) => {
    apiCall(POST, LOGOUT_URL, "", onSuccess, onFailure, token);
  });
}

// login with rsa api call
export function loginRsa(successLoginRsaCallBack, failureLoginRsaCallBack) {
  const onSuccess = (response) => {
    successLoginRsaCallBack(response);
  };

  const onFailure = (response) => {
    failureLoginRsaCallBack(response);
  };

  apiCall(GET, RSA_URL, "", onSuccess, onFailure);
}

//  export function changePassword(data, successChangePasswordCallBack, failureChangePasswordCallBack) {
//   const onSuccess = (response) => {
//     successChangePasswordCallBack(response);
//   };

//   const onFailure = (response) => {
//     failureChangePasswordCallBack(response);
//   };

//   apiCall(POST, CHANGE_PASSWORD, data, onSuccess, onFailure);
//  }
