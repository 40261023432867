// constant
import {
  ACCESS_TOKEN,
  USER_ID,
  REFRESH_TOKEN,
  POST,
  COMP_ID,
  COMP_CONFIG,
} from "./constants";

// plugin
import jwt_decode from "jwt-decode";

// function
import { refreshAccessToken } from "../common/connect";

// url
import { REFERSH_URL } from "../common/urls";

export async function _getStorageValue(storageKey) {
  try {
    if (storageKey === "ACCESS_TOKEN") {
      var refresh = jwt_decode(getRefreshToken());

      if (refresh.exp <= Math.round(new Date().getTime() / 1000)) {
        removeAllCookies();
        window.location.reload();
      } else {
        var access = jwt_decode(getAccessToken());
        if (access.exp <= Math.round(new Date().getTime() / 1000)) {
          var url = REFERSH_URL;
          refreshAccessToken(POST, url, getRefreshToken()).then((response) => {
            if (!response || response.status === false || !response.data) {
              removeAllCookies();
              window.location.reload();
            } else {
              setAccessToken(response.data.accessToken);
              setUserId(response.data.userId);
              setRefreshToken(response.data.refreshToken);
              return localStorage.getItem(storageKey);
            }
          });
        } else {
          return localStorage.getItem(storageKey);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
  return localStorage.getItem(storageKey);
}

export const setAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setUserId = (userId) => {
  localStorage.setItem(USER_ID, userId);
};

export const getUserId = () => {
  return localStorage.getItem(USER_ID);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const setCompId = (companyId) => {
  localStorage.setItem(COMP_ID, companyId);
};

export const getCompId = () => {
  return localStorage.getItem(COMP_ID);
};
export const setCompanyConfigs = (companyConfigs) => {
  localStorage.setItem(COMP_CONFIG, companyConfigs);
};

export const getCompanyConfigs = () => {
  return localStorage.getItem(COMP_CONFIG);
};

export const setAes = (key) => {
  cookies.set(AES_KEY, key);
};
export const removeAllCookies = () => {
  window.localStorage.clear();
};
