// react
import React, { useState } from "react";
// plugin
import { useHistory } from "react-router-dom";
// component
import { _getStorageValue } from "../../common/localStorage";

const GenerateTableLayout = (props) => {
  let history = useHistory();

  return <div className="generatetable-sections"></div>;
};

export default GenerateTableLayout;
