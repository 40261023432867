import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../adminPortal.css";
import modalClose from "../../../assets/images/view-modal-close.svg";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import { withStyles } from "@material-ui/core/styles";

const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: "transparent",
    border: "1px solid #3342B5",
    height: 24,
    position: "relative",
    fontStyle: "italic",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "#000",
      textTransform: "capitalize",
    },
    "& .MuiChip-deleteIcon": {
      color: "#3342B5",
    },
    "&:after": {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
})(Autocomplete);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  overflow: "auto",
  height: "650px",
  // p: 4,
};

const headerStyles = {
  padding: "15px",
  color: "#3342B5",
  fontWeight: "600",
  fontSize: "14px",
  textIndent: "25px",
  display: "flex",
  justifyContent: "space-between",
};

const CssTextField = styled(TextField)({
  "& .MuiInputLabel-outlined": {
    fontSize: "11px",
    fontWeight: "600",
    top: "-3px",
    fontStyle: "italic",
  },
  "& .MuiInputBase-formControl": {
    padding: "0",
  },
});

export default function Edituser({
  handleEditModalClose,
  viewUserManagementData,
  handleUserInput,
  shouldStoreRole,
  shouldShowEditUserModal,
  handleRoleChange,
  handleUserDetails,
  storeUserManagementObj,
  handleStateChange,
  handleDistrictChange,
  handlePageAccess,
  shouldStorePageAccess,
  handleDefaultLandingSelection,
  shouldStoreStates,
  shouldStoreDistricts,
  shouldEnableUpdateUserButton,
  storeDefaultLandingValue,
  invalidNumber,
  shouldStoreCreditHubNames,
  handleCreditHubChange,
  shouldStoreDefaultDistrict,
  storeDefaultCreditHubValue,
}) {
  // extract the user access level from local storage.
  const getUserAccessLevel = localStorage.getItem("user access level");

  // get the defaultRadioButtonSelection array from.
  const pageAccess = JSON.parse(
    localStorage.getItem("defaultRadioButtonSelection") || [],
  );
  // const getDefaultPage = JSON.parse(localStorage.getItem("default page"));

  const renameArrayItems = pageAccess.map((page) => {
    // Create a new object with the "default" key and its value set to the "default_page" value.
    // Remove the "default_page" key.
    const { default_page, ...rest } = page;
    return {
      ...rest,
      default: default_page,
    };
  });

  // Use map to set default_page to false for all objects
  const updatedData = renameArrayItems.map((item) => ({
    ...item,
    default: false,
  }));

  // sort the radioButtonSelectionWithoutSortingArray based on page no.
  const sortedArray = updatedData.sort((a, b) => a.page_no - b.page_no);

  // Create an empty array
  const values = [];
  // Extract the values from the object and store them in the array
  for (const obj of viewUserManagementData) {
    for (const val of Object.values(obj)) {
      values.push(val);
    }
  }

  // extract the key name from the viewUserManagementData array to map it with the id of the shouldRenderInputFields function.
  const keys = Object.keys(viewUserManagementData[0]);

  const storeKeyNamesForTheUserData = [
    "NAME OF USER",
    "E-MAIL ID",
    "PHONE NUMBER",
    "ROLE",
    "DESIGNATION",
    "STATE",
    "DISTRICT",
    "CREDIT HUB",
    "REPORT ACCESS",
    "DEFAULT LANDING SELECTION",
  ];

  const userRolesForSuperAdmin = [
    {
      role: "admin",
      id: "1",
    },
    {
      role: "user",
      id: "2",
    },
  ];

  const userRolesForAdmin = [
    {
      role: "user",
      id: "2",
    },
  ];

  const reportAccessArr = Array.isArray(values[8]) ? values[8] : [];
  const storeReports = [];
  reportAccessArr?.forEach((reports) => {
    storeReports.push({
      reportAccess: reports,
    });
  });

  function getSelectedItem() {
    var item;
    if (getUserAccessLevel === 1) {
      item = userRolesForAdmin.find((roles) => {
        if (roles.id === shouldStoreRole) {
          return roles;
        }
      });
    } else {
      item = userRolesForSuperAdmin.find((roles) => {
        if (roles.id === shouldStoreRole) {
          return roles;
        }
      });
    }
    return item;
  }

  // function to set the default state.
  function getSelectedState() {
    const state = shouldStoreStates.find((selectedState) => {
      if (selectedState.name === storeUserManagementObj.state) {
        return selectedState;
      }
    });
    return state;
  }

  // function to set the default credit hub.
  function getSelectedCreditHub() {
    let selectedCreditHub = {};
    selectedCreditHub = shouldStoreCreditHubNames.find((creditHub) => {
      if (creditHub === storeDefaultCreditHubValue) {
        return creditHub;
      }
    });
    return selectedCreditHub;
  }

  const defaultLandingSection = [
    {
      defaultPage: "Satsource Report",
      defaultLanding: "satsource",
      label: "Satsource",
      defaultPageNo: 1,
    },
    {
      defaultPage: "Satsource & Ownership Report",
      defaultLanding: "satsource & ownership",
      label: "Satsource & Ownership",
      defaultPageNo: 4,
    },
    {
      defaultPage: "Ownership Report",
      defaultLanding: "ownership",
      label: "Ownership",
      defaultPageNo: 6,
    },
  ];

  // function to store the matching labels corresponding to the report access array.
  function storeMatchingLabels() {
    const storeMatchingDropDownValues = [];
    shouldStorePageAccess?.forEach((items) => {
      defaultLandingSection.forEach((labels) => {
        if (items.page_name === labels.defaultLanding) {
          storeMatchingDropDownValues.push(labels);
        }
      });
    });
    return storeMatchingDropDownValues;
  }

  // function to set the default district name.
  function getSelectedDistrict() {
    let district = {};
    district = shouldStoreDistricts.find((selectedDistrict) => {
      if (selectedDistrict.name === shouldStoreDefaultDistrict) {
        return selectedDistrict;
      }
    });
    return district;
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // function to render the different types of input fields.
  function shouldRenderInputFields(items, i) {
    const keyName = keys[i];
    if (keyName === "role") {
      return (
        <CustomAutocomplete
          disablePortal
          defaultValue={getSelectedItem()}
          id="combo-box-demo"
          getOptionLabel={(option) => option.role.toUpperCase()}
          options={
            parseInt(getUserAccessLevel) === 1
              ? userRolesForAdmin
              : userRolesForSuperAdmin
          }
          onChange={(e, value, reason) => handleRoleChange(value, reason)}
          sx={{ width: "auto", marginBottom: "15px" }}
          ListboxProps={{
            style: {
              maxHeight: "100px",
              border: "1px solid dedede",
              overflowY: "auto",
              fontSize: "12px",
              textTransform: "capitalize",
            },
          }}
          renderInput={(params) => (
            <CssTextField
              size="small"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: "12px !important" },
              }}
              {...params}
              label="Select Role"
            />
          )}
        />
      );
    } else if (keyName === "state") {
      return (
        <CustomAutocomplete
          disablePortal
          disableClearable
          defaultValue={getSelectedState()}
          id="combo-box-demo"
          getOptionLabel={(option) => option.name}
          options={shouldStoreStates}
          onChange={(e, value) => handleStateChange(value)}
          sx={{ width: "auto", marginBottom: "15px" }}
          ListboxProps={{
            style: {
              maxHeight: "100px",
              border: "1px solid dedede",
              overflowY: "auto",
              fontSize: "12px !important",
            },
          }}
          renderInput={(params) => (
            <CssTextField
              size="small"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: "12px !important" },
              }}
              {...params}
              label="Select State"
            />
          )}
        />
      );
    } else if (keyName === "district") {
      if (shouldStoreDistricts.length == 0) {
        return null;
      }
      return (
        <CustomAutocomplete
          disablePortal
          value={getSelectedDistrict()}
          id="combo-box-demo"
          getOptionLabel={(option) => option.name}
          options={shouldStoreDistricts}
          onChange={(e, value, reason) => handleDistrictChange(value, reason)}
          sx={{ width: "auto", marginBottom: "15px" }}
          ListboxProps={{
            style: {
              maxHeight: "100px",
              border: "1px solid dedede",
              overflowY: "auto",
              fontSize: "12px",
            },
          }}
          renderInput={(params) => (
            <CssTextField
              size="small"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: "12px !important" },
              }}
              {...params}
              label="Select District"
            />
          )}
        />
      );
    } else if (keyName === "branch") {
      // if (shouldStoreCreditHubNames.length === 0) {
      //     return null;
      // }
      return (
        <CustomAutocomplete
          disablePortal
          disabled={shouldStoreCreditHubNames.length === 0 ? true : false}
          value={
            getSelectedCreditHub() !== undefined ? getSelectedCreditHub() : null
          }
          id="combo-box-demo"
          // getOptionLabel={(option) => option.name}
          options={shouldStoreCreditHubNames}
          onChange={(e, value, reason) => handleCreditHubChange(value, reason)}
          sx={{ width: "auto", marginBottom: "15px" }}
          ListboxProps={{
            style: {
              maxHeight: "100px",
              border: "1px solid dedede",
              overflowY: "auto",
              fontSize: "12px",
            },
          }}
          renderInput={(params) => (
            <CssTextField
              size="small"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: "12px !important" },
              }}
              {...params}
              label={
                shouldStoreCreditHubNames.length === 0
                  ? "No Credit Hub found"
                  : "Select Credit Hub"
              }
            />
          )}
        />
      );
    } else if (keyName === "pageAccess") {
      return (
        <CustomAutocomplete
          multiple
          disablePortal
          limitTags={1}
          options={sortedArray}
          value={shouldStorePageAccess}
          getOptionLabel={(option) => `${option.page_name} Reports`}
          sx={{ width: "auto", marginBottom: "15px" }}
          noOptionsText="No Matching Keyword"
          onChange={(e, value, reason) => handlePageAccess(value, reason)}
          renderOption={(props, option, { selected }) => {
            const isChecked = shouldStorePageAccess.some((items) => {
              return items.page_name === option.page_name;
            });
            return (
              <li {...props} style={{ fontSize: "13px", fontStyle: "italic" }}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={isChecked}
                />
                {`${option.page_name} Reports`}
              </li>
            );
          }}
          ListboxProps={{
            style: {
              maxHeight: "100px",
              border: "1px solid dedede",
              overflowY: "auto",
            },
          }}
          renderInput={(params) => (
            <CssTextField
              size="small"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: "12px !important" },
              }}
              {...params}
              label="Select Report Access"
            />
          )}
        />
      );
    } else if (keyName === "defaultLanding") {
      return (
        <CustomAutocomplete
          disablePortal
          value={storeDefaultLandingValue}
          options={storeMatchingLabels()}
          getOptionLabel={(option) => option.label}
          sx={{ width: "auto", marginBottom: "15px" }}
          noOptionsText="No Matching Keyword"
          onChange={(e, value, reason) =>
            handleDefaultLandingSelection(value, reason)
          }
          ListboxProps={{
            style: {
              maxHeight: "100px",
              border: "1px solid dedede",
              overflowY: "auto",
              fontSize: "12px",
            },
          }}
          renderInput={(params) => (
            <CssTextField
              size="small"
              InputProps={{
                ...params.InputProps,
                style: { fontSize: "12 !important" },
              }}
              {...params}
              label="Select Default Landing Selection"
            />
          )}
        />
      );
    } else if (keyName === "phoneNo") {
      return (
        <li key={i} style={{ paddingBottom: "20px", display: "block" }}>
          <input
            type="number"
            placeholder={
              items === null && `Enter ${storeKeyNamesForTheUserData[i]}`
            }
            defaultValue={items}
            onChange={(e) => handleUserInput(e.target.value, keyName)}
            disabled={keyName === "email" ? true : false}
            style={
              keyName === "email"
                ? { backgroundColor: "#dedede" }
                : {
                    backgroundColor: "transparent",
                    width: "-webkit-fill-available",
                  }
            }
            onKeyDown={(e) => {
              if (e.key === "0" && e.target.value.length == 0) {
                e.preventDefault();
              }
              if (e.key !== "Backspace" && e.target.value.length > 9) {
                e.preventDefault();
              }
            }}
          />
          {invalidNumber && (
            <p
              style={{
                color: "red",
                fontStyle: "italic",
              }}
            >
              Invalid phone number
            </p>
          )}
        </li>
      );
    }

    return (
      <li key={i} style={{ paddingBottom: "20px" }}>
        <input
          type="text"
          placeholder={
            items === null && `Enter ${storeKeyNamesForTheUserData[i]}`
          }
          defaultValue={items}
          onChange={(e) => handleUserInput(e.target.value, keyName)}
          disabled={keyName === "email" ? true : false}
          style={
            keyName === "email"
              ? { backgroundColor: "#dedede" }
              : { backgroundColor: "transparent" }
          }
        />
      </li>
    );
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={shouldShowEditUserModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="view-user-modal-wrapper">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={headerStyles}
              >
                <span>Edit User</span>
                <img
                  src={modalClose}
                  alt="Modal Close Image"
                  onClick={handleEditModalClose}
                />
              </Typography>
            </div>
            <div className="view-user-form-wrapper">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <ul>
                    {storeKeyNamesForTheUserData.map((keyNames, index) => {
                      return (
                        <li
                          key={index}
                          style={{ color: "#3342B5", paddingBottom: "36px" }}
                        >
                          {keyNames}
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
                <Grid item xs={7}>
                  <ul>
                    {values.map((items, index) => {
                      return shouldRenderInputFields(items, index);
                    })}
                  </ul>
                </Grid>
              </Grid>
              <div className="edit-user-form-button-wrapper">
                <Button
                  disabled={shouldEnableUpdateUserButton}
                  style={
                    shouldEnableUpdateUserButton
                      ? { opacity: "0.5", color: "#fff" }
                      : { opacity: "1" }
                  }
                  variant="contained"
                  onClick={() => handleUserDetails()}
                >
                  Update User
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
