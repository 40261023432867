import React from "react";
import { MuiThemeProvider, Tooltip, withStyles } from "@material-ui/core";

// Higher-Order Component to create a styled Tooltip
const createStyledTooltip = (color) =>
  withStyles({
    tooltip: {
      fontSize: "0.9rem",
      color: "white", // Text color
      backgroundColor: color || "#FF8A00", // Background color dynamically
      zIndex: 2,
    },
    arrow: {
      color: color || "#FF8A00", // Arrow color dynamically
    },
  })(Tooltip);

const CustomTooltip = ({ theme, title, icon, color, onClick }) => {
  // Create a styled Tooltip component with the passed color
  const DynamicTooltip = createStyledTooltip(color);
  return (
    <MuiThemeProvider theme={theme}>
      <DynamicTooltip title={title} arrow>
        <p
          style={{
            color: color,
            cursor: onClick ? "pointer" : "default",
            position: "relative",
            margin: 0,
          }}
          onClick={onClick}
        >
          <img src={icon} alt={title} />
        </p>
      </DynamicTooltip>
    </MuiThemeProvider>
  );
};

export default CustomTooltip;
