export const BASE_URL = process.env.REACT_APP_BASE_URL;
// for calling the dev api in order to fetch the district counts for the internal dashboard
export const DEV_URL = "https://dev.api.sage.satsure.co";
// for calling the prod api in order to fetch the district counts for the internal dashboard
export const PROD_URL = "https://api.satsure.co";

export const AUTH_BASE_URL = `${BASE_URL}/farm-level/v3/auth`;
export const BASE_URL3 = `${BASE_URL}/farm-level/v3/region`;
export const BASE_URL4 = `${BASE_URL}/farm-level/v3/sage`;

export const LOGIN_URL = `${AUTH_BASE_URL}/login`;
export const LOGOUT_URL = `${AUTH_BASE_URL}/logout`;
export const REFERSH_URL = `${AUTH_BASE_URL}/refresh-both-tokens`;

// base url for report status, starred and report regenration api.
const NEW_BASE_URL = `${BASE_URL}/farm-level/v3/sage`;

// login api with rsa key
export const RSA_URL = `${AUTH_BASE_URL}/rsa`;

//change password
export function changePasswordURL() {
  return `${BASE_URL}/farm-level/v3/admin/user-change-password `;
}

//forgot password- initiating otp
export function sendOTP() {
  return `${BASE_URL}/farm-level/v3/auth/password-recovery`;
}

//forgot password- otp verification
export function verifyOTP() {
  return `${BASE_URL}/farm-level/v3/auth/otp-verification`;
}

// State
export function getGenerateStateFilter(userId, radioButtonSelection) {
  return `${BASE_URL3}/${userId}?reportType=${radioButtonSelection}`;
}

// to call the region filter api.
export function getGenerateFilter(regionId, getStoreRadioButtonSelection) {
  return `${BASE_URL3}/${regionId}?reportType=${getStoreRadioButtonSelection}`;
}

// Land
export function getLandUrlFilter(regionId, getStoreRadioButtonSelection) {
  return `${BASE_URL3}/${regionId}/boundary?reportType=${getStoreRadioButtonSelection}`;
}
// Get DataBase Data
export function getArchiveTableData(pageNo, queryParam, searchParam) {
  let url = `${BASE_URL4}/user-requests?pageNo=${pageNo}`;

  if (queryParam) {
    url += `&queryParam=${queryParam}`;
  }

  if (searchParam) {
    url += `&searchParam=${searchParam}`;
  }

  return url;
}

//Get the status count
export function getStatusCount() {
  return `${BASE_URL4}/explorer-page-counts`;
}

// for survey numbers.
export function getSurveyNumbers(regionId) {
  return `${BASE_URL3}/${regionId}`;
}

export function getFullPdf(userId, requestId, reportStatus) {
  if (reportStatus === 3) {
    return `${BASE_URL4}/report/${requestId}?reportType=satsource&reportSize=full&readUnread=true`;
  } else {
    return `${BASE_URL4}/report/${requestId}?reportType=satsource&reportSize=full`;
  }
}
export function getPartialPdf(userId, requestId, reportStatus) {
  if (reportStatus === 3) {
    return `${BASE_URL4}/report/${requestId}?reportType=satsource&reportSize=partial&readUnread=true`;
  } else {
    return `${BASE_URL4}/report/${requestId}?reportType=satsource&reportSize=partial`;
  }
}
export function getCompactPdf(userId, requestId, reportStatus) {
  if (reportStatus === 3) {
    return `${BASE_URL4}/report/${requestId}?reportType=satsource&reportSize=compact&readUnread=true`;
  } else {
    return `${BASE_URL4}/report/${requestId}?reportType=satsource&reportSize=compact`;
  }
}
export function getOwnershipPdf(userId, requestId) {
  return `${BASE_URL4}/report/${requestId}?reportType=ownership`;
}

export function getPropertyPdf(userId, requestId) {
  return `${BASE_URL4}/report/${requestId}?reportType=propeye`;
}
// check for existing reference id
export function getExistingRefId(refNo) {
  return `${BASE_URL4}/reference-id/${refNo}`;
}

// new reference id
export function newRefId() {
  return `${BASE_URL}/farm-level/v3/sage/request-report-generation`;
}

// raise query api integration
export function raiseQuery() {
  return `${BASE_URL4}/raise-query`;
}

// missing report api integration
export function missingReport() {
  return `${BASE_URL4}/missing-data`;
}

// report regeneration api for satsource and monitoring.

export function reportRegeneration() {
  return `${NEW_BASE_URL}/request-report-regeneration`;
}

// table dropdown status api.
export function reportStatus(userId, requestId, reportStatus) {
  return `${NEW_BASE_URL}/set-report-status/${userId}/request_id/${requestId}?reportStatus=${reportStatus}`;
}

// starred status api.
export function starredStatus(userId) {
  return `${NEW_BASE_URL}/set-starred-status/${userId}/request_id`;
}

// archive status.
export function archiveStatus(userId) {
  return `${NEW_BASE_URL}/set-archive-status/${userId}/request_id`;
}

//to get notifications
export function getNotifications(userId) {
  return `${BASE_URL4}/user_id/${userId}/notification?limit=20`;
}

//to mark notifications are unread
export function changeReadStatus(userId) {
  return `${BASE_URL4}/user_id/${userId}/notification`;
}

// user permission role api.
export function userPermissionRole() {
  return `${BASE_URL}/farm-level/v3/master/user-page-permission`;
}

// admin user management table api.
export function adminUserManagement(pageNo) {
  return `${BASE_URL}/farm-level/v3/sage/users?pageNo=${pageNo}`;
}

// user management update details api.

export function updateUserDetails() {
  return `${BASE_URL}/farm-level/v3/admin/update-user-details`;
}

// admin data management table api.
export function adminDataManagement(
  reportName,
  pageNo,
  shouldStoreSearchKeyword,
  csv = false,
) {
  if (shouldStoreSearchKeyword) {
    return `${BASE_URL}/farm-level/v3/sage/requests?reportType=${reportName}&pageNo=${pageNo}&searchParam=${shouldStoreSearchKeyword}&csv=${csv}`;
  }
  return `${BASE_URL}/farm-level/v3/sage/requests?reportType=${reportName}&pageNo=${pageNo}&csv=${csv}`;
}

// download csv.
export function downloadCsv(reportName, pageNo, csv = true) {
  return `${BASE_URL}/farm-level/v3/sage/requests?reportType=${reportName}&pageNo=${pageNo}&csv=${csv}`;
}

// admin data management table api.
export function adminDataManagementfilter() {
  return `${BASE_URL}/farm-level/v3/sage/requests`;
}

// user creation api.
export function userCreation() {
  return `${BASE_URL}/farm-level/v3/admin/user-register`;
}

// admin analytics api.
export function analyticsData() {
  return `${BASE_URL}/farm-level/v3/admin/analytics`;
}

// credit hub api.
export function creditHubs() {
  return `${BASE_URL}/farm-level/v3/sage/branch_data`;
}

// download shape file.
export function shapeFile() {
  return `${BASE_URL}/farm-level/v3/region/download-shapefile`;
}

// data management search api.
export function dataManagementSearch(reportName, searchParam) {
  return `${BASE_URL}/farm-level/v3/sage/requests?reportType=${reportName}&searchParam=${searchParam}`;
}

// internal dashboard database page api to get the d1 and d2 count.
export function stateData() {
  return `${DEV_URL}/farm-level/v3/internal-dashboard/ownership-stats`;
}

// internal dashboard database prod ap to get the d1 and d2 count.
export function stateProdData() {
  return `${PROD_URL}/farm-level/v3/internal-dashboard/ownership-stats`;
}

// internal dashboard database page dev api to get the dev district level data.
export function districtDevData(stateRegionId, level, pageNo) {
  return `${DEV_URL}/farm-level/v3/internal-dashboard/ownership-stats?stateId=${stateRegionId}&level=${level}&pageNo=${pageNo}`;
}

// internal dashboard database page prod api to get the prod district level data.
export function districtProdData(stateRegionId, level, pageNo) {
  return `${PROD_URL}/farm-level/v3/internal-dashboard/ownership-stats?stateId=${stateRegionId}&level=${level}&pageNo=${pageNo}`;
}
