// react
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";

// plugin
import { useHistory, useLocation } from "react-router-dom";

// Action
import { login, loginRsa } from "./authentication";

// component
import CommonButton from "../../component/common-button/CommonButton";

// encode-decode
import { rsaEnc } from "../../common/encode-decode";

// style
import "../../layouts/login-layout/login.css";

// Images
import passwordhide from "../../assets/images/hide-eye.svg";
import passwordshow from "../../assets/images/show-eye.svg";
import satsureGreyLogo from "../../assets/images/satsure-grey.png";
import { Stack } from "@mui/material";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const LogInLayout = (props) => {
  let history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const location = useLocation();
  const [emailid, setEmailid] = useState("");
  const [hideShowPwd, setHideShowPwd] = useState(true);
  const [password, setPassword] = useState("");
  const [loginLoader, setLoginLoader] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [error, setError] = useState(null);
  const [isUpdatedPassword, setIsUpdatedPassword] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);

  // state to store the rsa key
  const [rsaKey, setRsaKey] = useState("");

  const triggerLogin = async () => {
    const token = await executeRecaptcha("login");

    if (!emailid || !password) {
      setError("Please enter your valid credentials");
      setInvalidMessage(true);
      setLoginLoader(false);
    } else {
      var userDetails = {
        // utilised the rsaEnc function here to encrypt the email id and password
        email: rsaEnc(emailid, rsaKey),
        password: rsaEnc(password, rsaKey),
        recaptcha_token: token,
      };
      login(userDetails, successLoginCallBack, failureLoginCallBack);
      setLoginLoader(true);
    }
  };
  const successLoginCallBack = (response) => {
    // save the user access level inside local storage.
    localStorage.setItem("user access level", response.data.userAccessLevel);
    // save the company id inside the local storage.
    localStorage.setItem("companyId", response.data.companyId);
    // save the configs inside the local storage.
    localStorage.setItem(
      "getCompanyConfigs",
      JSON.stringify(response.data.companyConfigs),
    );

    // save the active status inside the local storage.
    localStorage.setItem("activeStatus", response.data.active_status);
    sessionStorage.setItem("activeStatus", response.data.active_status);
    setLoginLoader(false);

    if (response.data.active_status === false) {
      history.push({
        pathname: "/changePassword",
        state: response.data.api_key,
      });
    } else {
      history.push("/generate-report");
    }
  };

  const failureLoginCallBack = (response) => {
    console.log(response);
    setLoginLoader(false);
    setError(response.message);
    setInvalidMessage(true);
  };

  // calling the rsa token api here
  useEffect(() => {
    loginRsa(successLoginRsaCallBack, failureLoginRsaCallBack);
    if (location.state === "isUpdatedPassword") {
      setIsUpdatedPassword(true);
    } else if (location.state === "isNewUser") {
      setIsNewUser(true);
    }
  }, []);

  // success and failure callbacks for the rsa login api
  const successLoginRsaCallBack = (response) => {
    setRsaKey(response.data.rsa);
  };
  const failureLoginRsaCallBack = (response) => {
    console.log(response);
  };

  return (
    <Stack className="login-sections">
      <div
        className={
          isUpdatedPassword || isNewUser ? "otp-heading" : "login-heading"
        }
      >
        <p>
          Welcome to <span>SatSure Sage</span>
        </p>
      </div>
      {isNewUser && (
        <p
          style={{
            fontWeight: "400",
            fontSize: "13px",
            fontStyle: "italic",
            color: "#0DB02B",
            paddingBottom: "1rem",
          }}
        >
          Please login with your new password.
        </p>
      )}
      {isUpdatedPassword && (
        <p
          style={{
            fontWeight: "400",
            fontSize: "13px",
            fontStyle: "italic",
            color: "#0DB02B",
            paddingBottom: "1rem",
          }}
        >
          Password changed successfully
        </p>
      )}
      <ul className="login-feild">
        <li>
          <label>Email</label>
          <span className="feild">
            <Form.Control
              type="email"
              className="custom-email-input"
              placeholder="Enter your mail address"
              value={emailid}
              required
              onKeyDown={(e) => {
                if (e.key == " ") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                setEmailid(e.target.value.replace(/\s/g, ""));
              }}
            />
          </span>
        </li>
        <li>
          <div className="form-group">
            <label className="group-title">Password</label>
            <span className="input-feild">
              <input
                type={hideShowPwd ? "password" : "text"}
                className={"input-text"}
                placeholder="Enter your password"
                name="password"
                onKeyDown={(e) => e.key === "Enter" && onSubmit("next")}
                value={password}
                required
                autocomplete="off"
                onChange={(e) => setPassword(e.target.value)}
              />
              <img
                src={hideShowPwd ? passwordhide : passwordshow}
                alt="passwordLogo"
                onClick={() => setHideShowPwd((prev) => !prev)}
                className="passwordIcon"
              />
            </span>
          </div>
        </li>
        {invalidMessage && (
          <li>
            <p
              style={{
                color: "red",
                fontStyle: "italic",
                fontSize: "14px",
              }}
            >
              {error}
            </p>
          </li>
        )}
      </ul>
      <div className="forgot-password">
        <p>
          <span
            onClick={(e) => {
              history.push({ pathname: "/forgotPassword" });
            }}
          >
            Forgot Password?
          </span>
        </p>
      </div>
      <div className="submit-btn">
        <CommonButton
          btnName={"log in"}
          btnCallBack={triggerLogin}
          btnLoader={loginLoader}
        />
      </div>
      <div className="second-logo-div">
        <p>Powered By</p>
        <div className="satsure-logo-div">
          <img src={satsureGreyLogo} />
        </div>
      </div>
    </Stack>
  );
};

export default LogInLayout;
