import { useEffect, useRef } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

export default function RenderGeoJson({ geoJSONData }) {
  const map = useMap();
  const mapLayer = useRef(L.featureGroup());

  useEffect(() => {
    if (mapLayer.current) {
      mapLayer.current.clearLayers(); // Clear previous layers
    }

    if (geoJSONData && geoJSONData.length > 0) {
      geoJSONData.forEach((fetchGeometryDetails) => {
        const mapBoundary = L.geoJSON(
          JSON.parse(fetchGeometryDetails.geometry),
        );
        mapLayer.current.addLayer(mapBoundary);
      });

      mapLayer.current.addTo(map);

      // Fit bounds to the new set of layers
      const bounds = mapLayer.current.getBounds();
      map.fitBounds(bounds);
    }
  }, [geoJSONData, map]);

  return null;
}
