import React from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import "./styles.css";
import pencilEdit from "../../assets/images/pencil-edit.svg";
import archieve from "../../assets/images/archieve.svg";
// import unarchieve data image.
import unArchiveDataImg from "../../assets/images/archieve-data.svg";
import { useSelector } from "react-redux";

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "1px solid #3342B5 !important",
  color: "#3342B5 !important",
  width: "40%",
  display: "flex",
  justifyContent: "flex-start",
  padding: "5px",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export default function Filterbuttons({
  handleAllStarred,
  tableIndex,
  status,
  handleAllReportRenegerate,
  handleAllArchieve,
  tableData,
  storeAllRowsSelected,
  handleAllUnArchieve,
  handleAllUnstarred,
}) {
  let checkArchiveStatus = useSelector(
    (s) => s.filterTableComponentReducer.checkArchiveStatus,
  );
  let starredButtonActiveColor = useSelector(
    (s) => s.filterTableComponentReducer.starredButtonActiveColor,
  );

  const getDefaultRadioButtonSelection =
    JSON.parse(localStorage.getItem("defaultRadioButtonSelection")) || [];
  let isPropEye = false;

  for (const item of getDefaultRadioButtonSelection) {
    if (
      (item.page_name === "property" ||
        item.page_name === "property & ownership") &&
      item.default_page
    ) {
      isPropEye = true;
      break;
    }
  }
  // this function will check if all the values inside the object is false.
  const renderStyleBasedOnCondition = () => {
    if (storeAllRowsSelected.length === 0) {
      return "none";
    } else {
      return "block";
    }
  };
  return storeAllRowsSelected.length === 0 ? null : checkArchiveStatus ? (
    <div
      className="button-wrapper"
      style={{ display: renderStyleBasedOnCondition() }}
    >
      <Stack direction="row" spacing={2}>
        <ColorButton
          variant="contained"
          onClick={() => handleAllUnArchieve(tableData)}
        >
          <img src={unArchiveDataImg} alt="pencilEdit" />
          <p>Unarchive</p>
        </ColorButton>
      </Stack>
    </div>
  ) : (
    <div
      className="button-wrapper"
      style={{ display: renderStyleBasedOnCondition() }}
    >
      <Stack direction="row" spacing={2}>
        <ColorButton
          variant="contained"
          onClick={() =>
            starredButtonActiveColor
              ? handleAllUnstarred(tableData)
              : handleAllStarred(status, tableIndex)
          }
        >
          <StarBorderIcon />
          <p>{starredButtonActiveColor ? "Unstar" : "Starred"}</p>
        </ColorButton>
        {isPropEye ? (
          ""
        ) : (
          <ColorButton
            variant="contained"
            onClick={() => handleAllReportRenegerate()}
          >
            <img src={pencilEdit} alt="pencilEdit" />
            <p>Regenerate</p>
          </ColorButton>
        )}
        <ColorButton
          variant="contained"
          onClick={() => handleAllArchieve(tableData)}
        >
          <img src={archieve} alt="pencilEdit" />
          <p>Archive</p>
        </ColorButton>
      </Stack>
    </div>
  );
}
